import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LogoVertical } from '../../vectors/Logo';

const ClipPath = () => (
  <svg width="0" height="0">
    <defs>
      <clipPath id="mask1" clipPathUnits="objectBoundingBox">
        <path d="M0,0 V0.803 C0.127,0.925,0.304,1,0.5,1 s0.373,-0.075,0.5,-0.197 V0" />
      </clipPath>
    </defs>
  </svg>
);

const ClipPathBorder = ({ clipHeight }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1800"
    height="1075"
    viewBox="0 0 1800 1075"
    preserveAspectRatio="none"
    style={{
      position: 'absolute',
      bottom: '-2px',
      left: 0,
      width: '100%',
      height: '100%',
      maxHeight: clipHeight || '600px',
      minHeight: '400px',
      pointerEvents: 'none',
      zIndex: 1,
    }}
  >
    <g>
      <path
        fill="var(--gold-2)"
        d="M0 853.7v10C227.866 994.009 546.878 1075 900 1075s672.134-80.991 900-211.3v-10C1572.134 984.009 1253.122 1065 900 1065S227.866 984.009 0 853.7"
      />
      <path fill="none" d="M0 0H1800V853.7H0z" />
    </g>
  </svg>
);

const HeroWrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 3;
  margin-top: -2px;
  background: ${({ clipBackground }) => clipBackground};
`;

const HeroImage = styled(GatsbyImage)`
  width: 100%;
  max-height: ${({ $gatsbyImageMaxHeight }) =>
    $gatsbyImageMaxHeight || '600px'};
  min-height: 400px;
  clip-path: url(#mask1);
  @media screen and (max-width: 810px) {
    min-height: ${({ $minHeightMobile }) => $minHeightMobile};
  }
  @media screen and (max-width: 1440px) {
    max-height: 700px;
  }
`;

const HeroContentBox = styled.div`
  width: 1240px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 2;
  padding: 0 var(--padding);
  @media screen and (max-width: 1000px) {
    padding-bottom: ${({ mobilePaddingBottom }) => mobilePaddingBottom};
  }
  @media screen and (max-width: 810px) {
    align-items: ${({ isHomePage }) =>
      isHomePage ? 'flex-start' : 'flex-end'};
  }
  @media screen and (max-width: 1240px) {
    width: 100%;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, var(--blue-1));
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  clip-path: url(#mask1);
  @media screen and (min-width: 811px) {
    display: none;
  }
`;

const SvgContainer = styled.div`
  display: flex;
  @media screen and (max-width: 810px) {
    justify-content: center;
    width: 100%;
    padding-top: var(--gap-2);
  }
  & svg {
    margin-top: -30%;
    @media screen and (max-width: 810px) {
      margin-top: unset;
      width: 90%;
    }
  }
`;

export const HeroClip = ({
  imageData,
  $scale,
  contentBox,
  heroRef,
  overlay,
  clipBackground,
  maxHeight,
  $minHeightMobile,
  $gatsbyImageMaxHeight,
  clipHeight,
  mobilePaddingBottom,
  isHomePage,
}) => (
  <HeroWrapper
    maxHeight={maxHeight}
    ref={heroRef}
    clipBackground={clipBackground}
  >
    <ClipPath />
    <HeroContentBox
      mobilePaddingBottom={mobilePaddingBottom}
      $scale={$scale}
      isHomePage={isHomePage}
    >
      {contentBox || (
        <SvgContainer>
          <LogoVertical />
        </SvgContainer>
      )}
    </HeroContentBox>
    {overlay && <Overlay />}
    <HeroImage
      $minHeightMobile={$minHeightMobile}
      $gatsbyImageMaxHeight={$gatsbyImageMaxHeight}
      loading="eager"
      alt="La Rosa dei gusti"
      image={imageData}
    />
    <ClipPathBorder clipHeight={clipHeight} />
  </HeroWrapper>
);

import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { RegularParagraph } from '../../styles/Typography';

const SlideGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;

  @media screen and (max-width: 350px) {
    width: calc(100vw - calc(var(--padding) * 2));
  }

  & img {
    border-radius: var(--gap-6);
  }
`;

const SlideImage = styled.img`
  width: 300px;
  height: 300px;

  @media screen and (max-width: 350px) {
    width: calc(100vw - calc(var(--padding) * 2));
  }
`;

const SlideTextContainer = styled.div`
  display: grid;
  row-gap: var(--gap-6);
`;

const SlideTitle = styled.h1`
  font-size: var(--font-size-3);
  color: var(--blue-2);
  line-height: 1.25;
`;

const Card = styled.article`
  display: grid;
  row-gap: var(--gap-4);
  padding-bottom: var(--gap-2);
`;

export const NewProductCard = ({
  title,
  description,
  gatsbyImageData,
  format,
  url,
}) => (
  <Card>
    {format === 'jpg' || format === 'png' ? (
      <SlideGatsbyImage
        image={gatsbyImageData}
        alt={title}
        style={{ borderRadius: format === 'jpg' ? '5px' : 0 }}
      />
    ) : (
      <SlideImage src={url} alt={title} />
    )}
    <SlideTextContainer>
      <SlideTitle>{title}</SlideTitle>
      <RegularParagraph>{description}</RegularParagraph>
    </SlideTextContainer>
  </Card>
);

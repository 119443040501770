import styled from 'styled-components';

const ContentBox = styled.div`
  display: grid;
  row-gap: var(--gap-6);
  justify-items: ${({ justifyLeft }) => justifyLeft || 'center'};
  z-index: 1;
  position: relative;

  @media screen and (max-width: 810px) {
    width: 100%;
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
  }

  @media screen and (min-width: 811px) {
    margin-top: -40px;
  }
`;

const Heading = styled.h1`
  color: ${({ color }) => (color ? color : 'var(--gold-2)')};
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
  text-align: left;
  max-width: 500px;
  width: 100%;
  line-height: 1.3;

  @media screen and (max-width: 810px) {
    font-size: var(--font-size-4);
    color: white;
  }
`;

export const Subtitle = styled.p`
  font-size: var(--font-size-3);
  font-weight: 500;
  line-height: 1.6;
  max-width: 500px;
  color: ${({ gold, color }) => (color ? color : (gold ? 'var(--foreground-white)' : 'var(--blue-2)'))};
  text-shadow: ${({ gold, color }) => (color ? '1px 1px 2px var(--foreground-white)' : 'none')};
  
  @media screen and (max-width: 480px) {
    font-size: var(--font-size-2);
  }
`;

export const AboutTextBox = ({
  heroTitle,
  heroTitleColor,
  heroSubtitle,
  heroSubtitleColor,
  paddingBottom,
  justifyLeft,
}) => (
  <ContentBox justifyLeft={justifyLeft} paddingBottom={paddingBottom}>
    <Heading color={heroTitleColor}>{heroTitle}</Heading>
    <Subtitle color={heroSubtitleColor} gold>{heroSubtitle}</Subtitle>
  </ContentBox>
);

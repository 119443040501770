import { graphql } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
// eslint-disable-next-line import/no-unresolved
import { SwiperSlide } from 'swiper/react';
import { ArticleCard } from '../components/layout/Cards/ArticleCard';
import { NewProductCard } from '../components/layout/Cards/NewProductCard';
import { PageWrapper } from '../components/layout/PageWrapper';
import { HeroClip } from '../components/layout/sections/SectionHero';
import { SwiperThree } from '../components/layout/sliders/SwiperThreeCols';
import { AboutTextBox } from '../components/misc/HeroContent';

const UpcomingPage = ({
  data: {
    datoCmsUpcomingPage: {
      seo,
      heroTitle,
      heroDescription,
      heroImage: { gatsbyImageData },
      heroImageMedium: { heroImageMediumData },
      titleSectionOne,
      titleSectionTwo,
    },
    allDatoCmsUpcomingProduct: { upcomingProductsNodes },
    allDatoCmsNews: { newsNodes },
  },
  pageContext,
}) => {
  const directedImages = withArtDirection(getImage(gatsbyImageData), [
    {
      media: '(max-width: 810px)',
      image: getImage(heroImageMediumData),
    },
  ]);
  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.image?.seoImageUrl}
    >
      <HeroClip
        scale="true"
        maxHeight="800px"
        overlay
        $gatsbyImageMaxHeight="800px"
        clipHeight="800px"
        $minHeightMobile="550px"
        imageData={directedImages}
        contentBox={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <AboutTextBox
            heroTitle={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <>
                {heroTitle.slice(0, 16)}
                <br />
                {heroTitle.slice(16)}
              </>
            }
            heroSubtitle={heroDescription}
            paddingBottom="110px"
          />
        }
      />

      <SwiperThree
        sectionTitle={titleSectionOne}
        mappedContent={upcomingProductsNodes.map(
          ({
            title,
            description,
            productImage: { gatsbyImageData: productImageData, format, url },
          }) => (
            <SwiperSlide key={title}>
              <NewProductCard
                title={title}
                gatsbyImageData={productImageData}
                description={description}
                format={format}
                url={url}
              />
            </SwiperSlide>
          )
        )}
      />

      <SwiperThree
        isLastSection
        sectionTitle={titleSectionTwo}
        backgroundColor="var(--gold-3)"
        mappedContent={newsNodes.map(
          ({
            id,
            title,
            shortDescription,
            cardImage: { gatsbyImageData: cardImageData },
          }) => (
            <SwiperSlide key={id}>
              <ArticleCard
                title={title}
                imageData={cardImageData}
                description={shortDescription}
                recordId={id}
              />
            </SwiperSlide>
          )
        )}
      />
    </PageWrapper>
  );
};

export default UpcomingPage;

export const query = graphql`
  query UpcomingPageQuery($locale: String!) {
    datoCmsUpcomingPage(locale: { eq: $locale }) {
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      heroTitle
      heroDescription
      heroImage {
        gatsbyImageData
      }
      heroImageMedium {
        heroImageMediumData: gatsbyImageData
      }
      titleSectionOne
      titleSectionTwo
    }
    allDatoCmsUpcomingProduct(
      filter: { locale: { eq: $locale } }
      sort: { fields: position }
    ) {
      upcomingProductsNodes: nodes {
        id: originalId
        description
        title
        productImage {
          gatsbyImageData
          format
          url
        }
      }
    }
    allDatoCmsNews(filter: { locale: { eq: $locale } }) {
      newsNodes: nodes {
        cardImage {
          gatsbyImageData(placeholder: NONE)
        }
        meta {
          firstPublishedAt
        }
        title
        shortDescription
        id: originalId
      }
    }
  }
`;

/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper } from 'swiper/react';
import { HeadingSection } from '../../styles/Typography';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

SwiperCore.use([Pagination]);

const Wrapper = styled.section`
  background: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ isLastSection }) =>
    isLastSection
      ? 'var(--gap-2) var(--padding) 160px var(--padding)'
      : 'var(--gap-2) var(--padding) var(--gap-4) var(--padding)'};
  margin-bottom: ${({ isLastSection }) =>
    isLastSection ? 'var(--wave-offset-negative-margin)' : 0};

  @media screen and (max-width: 768px) {
    padding: ${({ isLastSection }) =>
      isLastSection
        ? 'var(--gap-2) var(--padding) 140px var(--padding)'
        : 'var(--gap-2) var(--padding) var(--gap-4) var(--padding)'};
  }
`;

const Container = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-2);
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    gap: var(--gap-4);
  }

  @media screen and (max-width: 1140px) {
    width: 100%;
  }
`;

const SwiperContainer = styled.div`
  display: block;
  width: 100%;
`;

export const SwiperThree = ({
  isLastSection,
  backgroundColor,
  sectionTitle,
  mappedContent,
}) => {
  return (
    <Wrapper backgroundColor={backgroundColor} isLastSection={isLastSection}>
      <Container>
        <HeadingSection blueHeading>{sectionTitle}</HeadingSection>
        <SwiperContainer>
          <Swiper
            style={{
              '--swiper-navigation-color': 'var(--gold-2)',
              '--swiper-pagination-color': 'var(--gold-2)',
            }}
            grabCursor
            pagination={{ clickable: true }}
            slidesPerView={1}
            spaceBetween={40}
            breakpoints={{
              350: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 60,
              },
            }}
          >
            {mappedContent}
          </Swiper>
        </SwiperContainer>
      </Container>
    </Wrapper>
  );
};

import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { RegularParagraph } from '../../styles/Typography';
import { NavigatorButtonNormal } from '../../ui/Buttons';

const ArticleContainer = styled.article`
  display: grid;
  row-gap: 15px;
`;

const ArticleImage = styled(GatsbyImage)`
  width: 100%;
  border-radius: 5px;
  height: 200px;

  & img {
    border-radius: 5px;
  }
`;

const SlideTitle = styled.h1`
  font-size: var(--font-size-3);
  color: var(--blue-2);
  line-height: 1.25;
`;

export const ArticleCard = ({ title, description, recordId, imageData }) => {
  const intl = useIntl();

  return (
    <ArticleContainer>
      <ArticleImage image={imageData} alt={title} />
      <SlideTitle>{title}</SlideTitle>
      <RegularParagraph>{description}</RegularParagraph>
      <NavigatorButtonNormal
        $bolder
        $buttonGold
        recordId={recordId}
        buttonText={intl.formatMessage({ id: 'discoverMore' })}
      />
    </ArticleContainer>
  );
};
